import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import Circle from "../components/Circle"
import Figure from "../components/Figure"
import Frame from "../components/Frame"
import { H1, H2, H3, H4, Ul, P, Li_squared } from "../components/VerticalRhythm"
import { Flex, Box } from "rebass"

import PictureSet from "../components/PictureSet"
import imgObj04 from "../imgObjs/imgObj04"

import umschlagVS from "../img/0500/Schreib-und-das-Leben-ist-leicht-UmschlagVS.jpg"

import breakpoints from "../theme/breakpoints"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Bestellen"
      keywords={[
        `Vera Anders`,
        `eigenes Buch`,
        `wertiges Geschenk`,
        `Unternehmen`,
        `Workshop`,
        `Schreiben im Unternehmen`,
      ]}
    />
    <Figure caption="">
      <PictureSet imgObj={imgObj04} />
    </Figure>
    <Section>
      <Circle py={4}>
        <FontAwesomeIcon icon={["fal", "shopping-cart"]} size="3x" />
      </Circle>
      <H1>Bestellen</H1>
      <P>
        Sie freuen sich auf Ihr eigenes Buch, suchen ein wertiges und sinnvolles
        Geschenk oder möchten Ihren Mitarbeitern oder Kollegen eine Freude mit
        Nutzen machen.
      </P>
      <Flex mb={3} alignItems={"flex-end"}>
        <Box width={"150px"} mr={2}>
          <img src={umschlagVS} alt="" />
        </Box>
        <Box pl={2}>
          <P mb={0}>
            Illustrierte Broschur mit Innenklappe
            <br />
            17,90€
            <br />
            ISBN 9783000636653
          </P>
        </Box>
      </Flex>

      <P>Bitte bestellen Sie »Schreib und das Leben ist leicht«</P>

      <ul>
        <li>
          auf Rechnung oder mit Vorkasse per Mail an{" "}
          <a href="mailto: vera.anders@schreib-leicht.de">
            vera.anders@schreib-leicht.de
          </a>
          .<P>Dazu geben Sie bitte folgendes an:</P>
          <Ul>
            <Li_squared>Ihren Namen/den Namen Ihres Unternehmens</Li_squared>
            <Li_squared>vollständige Liefer- und Rechnungsadresse </Li_squared>
            <Li_squared>die Anzahl der Bücher</Li_squared>
            <Li_squared>
              optional: Ihren Text für eine handschriftliche Widmung
            </Li_squared>
          </Ul>
          <P>
            Das Buch kostet 17,90€ plus 1,90€ Porto. Ab einer Bestellung von
            zwei Büchern übernehme ich die Versandkosten.
          </P>
          <P>Lieferdauer: etwa zwei bis fünf Werktage.</P>
        </li>
        <li>
          über meinen{" "}
          <a
            href="https://schreibunddaslebenistleicht.bigcartel.com/"
            _target="_blank"
            rel="noreferrer noopener"
          >
            Online-Shop mit&nbsp;
            <FontAwesomeIcon
              style={{
                position: "relative",
                marginTop: "-14px",
                top: "10px",
                left: "4px",
              }}
              icon={["fab", "cc-paypal"]}
              size="2x"
            />
          </a>
        </li>
        <li>
          bei Ihrem Buchhändler. Der Titel steht seit Anfang 2020 im Verzeichnis
          lieferbarer Bücher (VLB).
        </li>
        <li>
          ab sofort auf{" "}
          <a
            href="https://www.osiander.de/webdb/index.cfm?osiaction=details&id=VLBf3473ef236cb4890ae3429c74de0b08a&source=UWK"
            target="_blank"
            rel="noreferrer noopener"
          >
            OSIANDER.de
          </a>{" "}
          oder über{" "}
          <a
            href="https://www.amazon.de/Schreib-das-Leben-ist-leicht/dp/300063665X/ref=sr_1_1?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=9783000636653&qid=1579877924&s=books&sr=1-1"
            target="_blank"
            rel="noreferrer noopener"
          >
            Amazon
          </a>
          .
        </li>
      </ul>
      <P>
        <strong>Für Unternehmen:</strong>
        <br />
        Bei Firmenbestellungen ab 25 Exemplaren bedanke ich mich mit einem
        Mini-Texterworkshop im Wert von 200€ (außerhalb des Rheinlands berechne
        ich eine Fahrtkostenpauschale). Das Thema der Veranstaltung wählen Sie
        oder wir besprechen es gemeinsam.
      </P>
    </Section>
  </Layout>
)

export default IndexPage
